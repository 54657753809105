import {Injectable} from "@angular/core";
import {BookingRes, DayAddress, TimeSlot} from "../api-clients/booking/clients";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {CalcPromosRes, MasterRes, PromoRes} from "../api-clients/master-list/clients";
import {ClientVm} from "../client/clients-vm";
import {ParamMap} from "@angular/router";
import {TimeSlotVm} from "../utils/DayVm";

export class ServicesData {
  public serviceTypeIds: string[];
  public serviceItems: ServiceItem[];
  public customTime: boolean;
  public durationInMinutes: number;
  public restInMinutes: number;
  public frequency: number;
  public itemCanBeBookedByMasterOnlyExist: boolean;
}

export class ServiceItem {
  public priceListItemId: string;
  public serviceTypeId: string;
  public serviceTypeName: string;
  public priceMin: number;
  public priceMax: number;
  public durationInMinutes: number;
  public restInMinutes: number;
  public canBeBookedByMasterOnly: boolean;
  public promo: PromoRes;
}

export class TimeSlotData {
  public master: MasterRes;
  public address: DayAddress;
  public timeSlot: TimeSlotVm;
}

@Injectable()
export class BookingWizardSettings {
  public clientId: string;
  public masterId: string;
  public saloonId: string;

  public serviceTypeIds: string[];
  public date: Date;

  public baseBookingId: string;
  public withSequence: boolean;


  public fillByQueryParamMap(qpm: ParamMap): void {
    this.clientId ??= qpm.get('clientId');
    this.masterId ??= qpm.get('masterId');
    this.saloonId ??= qpm.get('saloonId');

    this.serviceTypeIds ??= qpm.getAll('serviceTypeIds');
    this.date ??= qpm.get('date')
      ? new Date(qpm.get('date'))
      : null;

    this.baseBookingId ??= qpm.get('baseBookingId');
    this.withSequence ??= qpm.get('withSequence') == 'true';
  }

  public clear(): void {
    this.clientId = null;
    this.masterId = null;
    this.saloonId = null;

    this.serviceTypeIds = null;
    this.date = null;

    this.baseBookingId = null;
    this.withSequence = null;
  }


  public navigateToMasterInfo: (id: string) => Promise<void>;
  public navigateToSaloonInfo: (id: string) => Promise<void>;
  public navigateToBalance: () => Promise<void>;
}

@Injectable()
export class BookingWizardController {

  private resolve: (value: void | PromiseLike<void>) => void;
  private _initialized: Promise<void> = null;

  private _baseBookingSubj = new BehaviorSubject<BookingRes>(null);
  private _servicesDataSubj = new BehaviorSubject<ServicesData>(null);
  private _timeSlotDataSubj = new BehaviorSubject<TimeSlotData>(null);
  private _clientDataSubj = new BehaviorSubject<ClientVm>(null);
  private _notificationRequiredSubj = new BehaviorSubject<boolean>(false);
  private _reminderTimeInMinutesSubj = new BehaviorSubject<number>(null);
  private _masterNoteSubj = new BehaviorSubject<string>(null);
  private _calcPromosSubj = new BehaviorSubject<CalcPromosRes[]>([]);

  private _confirmedSubj = new BehaviorSubject<boolean>(false);
  private _disposedSubj = new BehaviorSubject<boolean>(false);


  public get initialized(): Promise<void> {
    return this._initialized;
  }


  public get baseBooking(): BookingRes {
    return this._baseBookingSubj.value;
  }

  public get servicesData(): ServicesData {
    return this._servicesDataSubj.value;
  }

  public get timeSlotData(): TimeSlotData {
    return this._timeSlotDataSubj.value;
  }

  public get clientVm(): ClientVm {
    return this._clientDataSubj.value;
  }

  public get notificationRequired(): boolean {
    return this._notificationRequiredSubj.value;
  }

  public get reminderTimeInMinutes(): number {
    return this._reminderTimeInMinutesSubj.value;
  }

  public get masterNote(): string {
    return this._masterNoteSubj.value;
  }

  public get calcPromosAllMasters(): CalcPromosRes[] {
    return this._calcPromosSubj.value;
  }

  public calcPromosByMasterId(masterId: string): CalcPromosRes[] {
    return this._calcPromosSubj.value.filter(c => c.promo.masterId == masterId);
  }

  public get confirmed(): boolean {
    return this._confirmedSubj.value;
  }

  public get disposed(): boolean {
    return this._disposedSubj.value;
  }


  public get baseBooking$(): Observable<BookingRes> {
    return this._baseBookingSubj.asObservable();
  }

  public get servicesData$(): Observable<ServicesData> {
    return this._servicesDataSubj.asObservable();
  }

  public get timeSlotData$(): Observable<TimeSlotData> {
    return this._timeSlotDataSubj.asObservable();
  }

  public get clientData$(): Observable<ClientVm> {
    return this._clientDataSubj.asObservable();
  }

  public get notificationRequired$(): Observable<boolean> {
    return this._notificationRequiredSubj.asObservable();
  }

  public get reminderTimeInMinutes$(): Observable<number> {
    return this._reminderTimeInMinutesSubj.asObservable();
  }

  public get masterNote$(): Observable<string> {
    return this._masterNoteSubj.asObservable();
  }

  public get calcPromosSubj$(): Observable<CalcPromosRes[]> {
    return this._calcPromosSubj.asObservable();
  }

  public get confirmed$(): Observable<boolean> {
    return this._confirmedSubj.asObservable();
  }

  public get disposed$(): Observable<boolean> {
    return this._disposedSubj.asObservable();
  }


  public constructor(private _wizardSettings: BookingWizardSettings) {
    this._initialized = new Promise<void>((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  public async init(fn: () => Promise<void>): Promise<void> {
    await fn();
    this.resolve();
  }

  public setBaseBooking(baseBooking: BookingRes): void {
    this._baseBookingSubj.next(baseBooking);
  }

  public setServicesData(servicesData: ServicesData): void {
    this._servicesDataSubj.next(servicesData);
  }

  public setTimeSlotData(timeSlotData: TimeSlotData): void {
    this._timeSlotDataSubj.next(timeSlotData);
  }

  public setClientVm(clientVm: ClientVm): void {
    this._clientDataSubj.next(clientVm);
  }

  public setNotificationRequired(notificationRequired: boolean): void {
    this._notificationRequiredSubj.next(notificationRequired);
  }

  public setReminderTimeInMinutes(reminderTimeInMinutes: number): void {
    this._reminderTimeInMinutesSubj.next(reminderTimeInMinutes);
  }

  public setMasterNote(masterNote: string): void {
    this._masterNoteSubj.next(masterNote);
  }

  public setCalcPromosSubj(calcPromos: CalcPromosRes[]): void {
    this._calcPromosSubj.next(calcPromos);
  }

  public confirm(): void {
    this._confirmedSubj.next(true);
  }

  public dispose(): void {
    this._disposedSubj.next(true);
  }
}

@Injectable()
export class BookingDetailsSettings {

  public navigateToMaster: (id: string) => Promise<void>;
  public navigateToClient: (id: string) => Promise<void>;

  public constructor() {
  }
}
