import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {FormControl, FormGroup} from "@angular/forms";
import {combineLatest, firstValueFrom} from "rxjs";
import {PriceListItem, PriceListVm} from "../../../price-list/price-list-vm";
import {CalcPromosRes, MasterListClient, MasterRes} from "../../../api-clients/master-list/clients";
import {SpecialitiesDirectoryService} from "../../../directories/specialities-directory.service";
import {SaloonsClient} from "../../../api-clients/saloon/clients";
import {DatesValidator} from "../../../utils/dates.validator";
import {dateStringToMinutes, minutesToDateString} from "../../../date-processing/functions";
import {BookingWizardController, BookingWizardSettings, ServiceItem} from "../../controller";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";
import {NavigationService} from "../../../utils/navigation.service";
import {AuthService} from "../../../security/auth.service";
import {ChoosePriceListItemPage} from "../../../price-list/choose-price-list-item/choose-price-list-item.page";
import {ActionSheetOptions} from "@ionic/core/dist/types/components/action-sheet/action-sheet-interface";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {

  public masters: MasterRes[] = [];

  public priceListVm: PriceListVm;
  public priceListVmSelected: PriceListVm;
  public priceListVmUnselected: PriceListVm;
  public serviceTypeIdsSelected: string[] = [];

  public form: FormGroup;

  public frequencyControl = new FormControl(0);

  public get wc(): BookingWizardController {
    return this._wizardController;
  }

  public get itemCanBeBookedByMasterOnlyExist(): boolean {
    return this.priceListVmSelected?.getAllItems().some(i => i.canBeBoockedByMasterOnly == true);
  }

  // public get masterWithForbiddenService(): MasterRes {
  //   const forbiddenService = this.serviceItems.find(i => i.canBeBookedByMasterOnly == true);
  //   return this.masters.find(m => m.priceList.priceListItems.some(p => p.id == forbiddenService?.priceListItemId)) ?? null;
  // }

  public constructor(
    public applicationIdService: ApplicationIdService,
    private _wizardSettings: BookingWizardSettings,
    private _wizardController: BookingWizardController,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
    private _navigationService: NavigationService,
    private _modalController: ModalController,
    private _specialitiesDirectory: SpecialitiesDirectoryService,
    private _masterListClient: MasterListClient,
    private _saloonsClient: SaloonsClient,
    private _authService: AuthService,
  ) {
  }

  public async ngOnInit(): Promise<void> {

    await this._wizardController.initialized;

    if (this._wizardController.disposed || (!this._wizardSettings.masterId && !this._wizardSettings.saloonId)) {
      await this._navigationService.goBack();
      return;
    }

    this.form = new FormGroup({
      customTimeControl: new FormControl(false),
      durationTimeControl: new FormControl(null, [DatesValidator.isZeroLength]),
      restTimeControl: new FormControl(null, [DatesValidator.isZeroLength]),
      customFrequencyControl: new FormControl(false),
      frequencyControl: new FormControl(this._wizardSettings.withSequence
        ? this._wizardController?.baseBooking?.sequenceFrequency || 0
        : 0)
    });

    let masterIds = this._wizardSettings.masterId
      ? Promise.resolve([this._wizardSettings.masterId])
      : this._saloonsClient.getSaloonById(this._wizardSettings.saloonId).toPromise().then(s => (s.saloon.connections.map(c => c.masterId)).filter(id => id != null));

    await masterIds.then(masterIds => {
      combineLatest([
        this._masterListClient.getMasters(masterIds, null),
        firstValueFrom(this._specialitiesDirectory.getSpecialities(masterIds)),
      ]).subscribe(([masters, specs]) => {
        if (this.applicationIdService.applicationId == "client" && this._authService.isAuthenticated){
          let calcPromos: CalcPromosRes[] = [];

          if (this._wizardController.baseBooking) {
            let promos = masters.find(m => m.id == this._wizardSettings.masterId).promos.filter(p => this._wizardController.baseBooking.items.map(i => i.promoId).indexOf(p.id) != -1);

            for (let promo of promos) {
              calcPromos = [...calcPromos, ...promo.priceListItems.map(pli => {
                return {priceListItemId: pli.id, promo: promo} as CalcPromosRes
              })];
            }

            console.log('xxx', this.wc.calcPromosAllMasters);
            console.log('yyy', calcPromos);

            this.wc.setCalcPromosSubj([...this.wc.calcPromosAllMasters,...calcPromos]);
          }
        }

        this.masters = masters;
        this.priceListVm = PriceListVm.ParseFromMasters(masters, specs, this.wc.calcPromosAllMasters, false, this.applicationIdService.applicationId != 'client');

        this.serviceTypeIdsSelected = this._wizardSettings.serviceTypeIds ?
          this.priceListVm.getAllItems().filter(i => this._wizardSettings.serviceTypeIds.some(id => id == i.serviceTypeId)).map(i => i.serviceTypeId) :
          [];
        this.updateSelectedVm();

        if (this.serviceTypeIdsSelected.length > 0) {
          if (this._wizardController.baseBooking?.serviceDurationInMinutes) {
            this.form.controls.durationTimeControl.setValue(minutesToDateString(this._wizardController.baseBooking?.serviceDurationInMinutes));
          }
          if (this._wizardController.baseBooking?.restDurationInMinutes) {
            this.form.controls.restTimeControl.setValue(minutesToDateString(this._wizardController.baseBooking?.restDurationInMinutes));
          }

        } else {
          this.onChooseItemClick().then();
        }
      });
    });
  }

  public async onChooseItemClick(): Promise<void> {
    const modal = await this._modalController.create({
      component: ChoosePriceListItemPage,
      componentProps: {
        priceList: this.priceListVmUnselected.clone(),
        showSpecialities: this._wizardSettings.masterId == null && this._wizardSettings.saloonId != null,
      },
    });

    modal.onDidDismiss().then(async (modal) => {
      if (!modal.data) {
        return;
      }

      if (this.serviceTypeIdsSelected.some(i => i == modal.data.serviceTypeId)) {
        return;
      }

      this.serviceTypeIdsSelected.push(modal.data.serviceTypeId);
      await this.updateSelectedVm();
    });

    await modal.present();
  }

  public async onUnchooseItemClick(item: PriceListItem): Promise<void> {
    this.serviceTypeIdsSelected = this.serviceTypeIdsSelected.filter(i => i != item.serviceTypeId);
    await this.updateSelectedVm();
  }

  public updateSelectedVm() {
    let priceListVm = this.priceListVm.clone();

    for (let spec of priceListVm.specialities) {
      for (let group of spec.groups) {
        group.items = group.items.filter(i => this.serviceTypeIdsSelected.indexOf(i.serviceTypeId) != -1);
      }
      spec.groups = spec.groups.filter(g => g.items.length != 0);
    }

    this.priceListVmSelected = priceListVm;

    let priceListVmUnselected = this.priceListVm.clone();

    for (let spec of priceListVmUnselected.specialities) {
      for (let group of spec.groups) {
        group.items = group.items.filter(i => this.serviceTypeIdsSelected.indexOf(i.serviceTypeId) == -1);
      }
      spec.groups = spec.groups.filter(g => g.items.length != 0);
    }

    this.priceListVmUnselected = priceListVmUnselected;
  }

  async onNextClick() {
    const newServiceItems: ServiceItem[] = [];

    for (let priceListItem of this.priceListVmSelected.getAllItems()) {
      if (this._wizardController.baseBooking) {

        const baseItem = this._wizardController.baseBooking.items.find(i => i.serviceTypeId == priceListItem.serviceTypeId);
        if (baseItem) {
          const newServiceItem = new ServiceItem();

          newServiceItem.priceListItemId = baseItem.priceListItemId;
          newServiceItem.serviceTypeId = baseItem.serviceTypeId;
          newServiceItem.serviceTypeName = baseItem.serviceTypeName;
          newServiceItem.priceMin = baseItem.priceMin;
          newServiceItem.priceMax = baseItem.priceMax;
          newServiceItem.durationInMinutes = baseItem.durationInMinutes;
          newServiceItem.restInMinutes = 0;
          newServiceItem.canBeBookedByMasterOnly = false;

          newServiceItems.push(newServiceItem);

          continue;
        }
      }

      const newServiceItem = new ServiceItem();
      newServiceItem.priceListItemId = priceListItem.id;
      newServiceItem.serviceTypeId = priceListItem.serviceTypeId;
      newServiceItem.serviceTypeName = priceListItem.serviceTypeName;
      newServiceItem.priceMin = priceListItem.priceMin;
      newServiceItem.priceMax = priceListItem.priceMax;
      newServiceItem.durationInMinutes = priceListItem.durationInMinutesMax;
      newServiceItem.restInMinutes = priceListItem.requiredRestInMinutes;
      newServiceItem.canBeBookedByMasterOnly = priceListItem.canBeBoockedByMasterOnly;

      newServiceItems.push(newServiceItem);
    }

    const customTime = this.form.controls.customTimeControl.value;
    const durationValue = customTime ? dateStringToMinutes(this.form.controls.durationTimeControl.value) : null;
    const restValue = customTime ? dateStringToMinutes(this.form.controls.restTimeControl.value) : null;

    const frequencyValue = this.form.controls.customFrequencyControl ? this.form.controls.frequencyControl.value : null;

    this._wizardController.setServicesData({
      serviceTypeIds: [...this.serviceTypeIdsSelected],
      serviceItems: [...newServiceItems],
      customTime: customTime,
      durationInMinutes: durationValue,
      restInMinutes: restValue,
      frequency: frequencyValue,
      itemCanBeBookedByMasterOnlyExist: this.itemCanBeBookedByMasterOnlyExist,
    });
  }

  public customActionSheetHeader(header: string): ActionSheetOptions {
    return {
      buttons: [],
      header: header,
    }
  }

  public onGoToInfoClick(): void {
    if (this._wizardSettings.masterId) {
      this._wizardSettings.navigateToMasterInfo(this._wizardSettings.masterId).then();
      return;
    }

    if (this._wizardSettings.saloonId) {
      this._wizardSettings.navigateToSaloonInfo(this._wizardSettings.saloonId).then();
      return;
    }
  }
}
